import * as CSS from 'csstype'
import { createStyles } from 'utils/css'

interface IContentScrollStyles {
  maxHeight: string
  styles?: CSS.Properties
}

export const root = ({ maxHeight, styles }: IContentScrollStyles) =>
  createStyles(({ colors, radii, spacing }) => ({
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: spacing(1.5),
    maxHeight: `calc(${maxHeight} - 60px)`,
    scrollbarWidth: 'thin',
    scrollbarColor: `${colors.grey25} ${colors.grey25}`,
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: colors.grey10,
      borderRadius: radii.md
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.grey25,
      borderRadius: radii.md,
      border: `1px solid ${colors.grey25}`
    },
    ...styles
  }))
