import * as CSS from 'csstype'
import { createStyles } from 'utils/css'
import theme from 'utils/theme'

import { ICardStyles } from './Card.types'

export const root = ({
  alignment,
  background,
  borderColor,
  borderRadius,
  borderStyle,
  borderWidth,
  foreground,
  fullHeight,
  margin,
  padding,
  shadow,
  styles,
  width
}: ICardStyles) =>
  createStyles(({ alignments, colors, radii, shadows, size, spacing }) => {
    const defaults = {
      borderWidth: `${borderWidth}px`,
      borderColor: colors[borderColor],
      borderStyle,
      borderRadius: borderRadius && radii[borderRadius],
      boxShadow: shadow && shadows[shadow],
      textAlign: alignment && alignments[alignment],
      color: foreground && colors[foreground],
      margin: !margin ? 'auto' : spacing(margin),
      padding: spacing(padding),
      width: '100%',
      maxWidth: width && size(width),
      backgroundColor: colors[background],
      height: fullHeight && '100%'
    }
    return { ...defaults, ...styles }
  })

const badgeStyles: CSS.Properties = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  maxHeight: '19px'
}

export const expiredBadgeStyles: CSS.Properties = {
  ...badgeStyles,
  background: theme.colors.danger50,
  border: `1px solid ${theme.colors.danger100}`
}

export const expiredTextStyles: CSS.Properties = {
  marginLeft: '4px',
  fontSize: theme.size(1)
}

export const defaultBadgeStyles: CSS.Properties = {
  ...badgeStyles,
  border: `1px solid ${theme.colors.primary}`
}
