import PasswordIcon from '@carbon/icons-react/lib/Password'
import Input, { IInput } from 'lib/ui/Input/v2'
import InputPassword from 'lib/ui/InputPassword/v2'

/**
 * Base inputs to use for app, allows fo easy
 * overrides if need to depart from the
 * default lib inputs
 */

export const Password = ({
  inputSize,
  type,
  testid,
  disabled,
  error,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  styles,
  value,
  currency,
  label,
  foreground,
  hideLabel,
  id,
  labelProps,
  required
}: Omit<IInput, 'ref'>) => (
  <InputPassword
    inputSize={inputSize}
    type={type}
    testid={testid}
    disabled={disabled}
    error={error}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    onFocus={onFocus}
    placeholder={placeholder}
    styles={styles}
    value={value}
    currency={currency}
    label={label}
    foreground={foreground}
    hideLabel={hideLabel}
    id={id}
    labelProps={labelProps}
    required={required}
    icon={<PasswordIcon />}
  />
)

const BaseInput = ({
  inputSize,
  type,
  hideLabel,
  id,
  labelProps,
  required,
  testid,
  disabled,
  error,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  value,
  currency,
  styles,
  foreground,
  label
}: Omit<IInput, 'ref'>) => (
  <Input
    inputSize={inputSize}
    type={type}
    hideLabel={hideLabel}
    id={id}
    labelProps={labelProps}
    required={required}
    testid={testid}
    disabled={disabled}
    error={error}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    onFocus={onFocus}
    placeholder={placeholder}
    value={value}
    currency={currency}
    styles={styles}
    foreground={foreground}
    label={label}
  />
)

export default BaseInput
